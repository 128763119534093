export const HEADERS = {
  FIO: 'Фамилия, Имя кандидата',
  GENDER: 'Пол',
  AGE: 'Возраст',
  BIRTHDATE: 'Год рождения',
  STATUS: 'Статус кандидата',
  TEST_TIME: 'Дата тестирования',
  TESTING_STATUS: 'Статус тестирования',
  COMPANY: 'Компания',
  DEGREE_POSITION_COMPLIANCE: 'Степень соответствия указанной должности',
  RISK_FACTORS: 'Факторы риска',
  MANAGEMENT_STYLE: 'Стиль управления',
  POSITION: 'Должность',
  ATTENTION_FACTOR: 'Зоны внимания',
  SCALES: 'Шкалы',
  TEAM_ROLES: 'Роли в команде',
  TEAM_ROLES_FIRST_1: 'Ведущая Роль 1',
  TEAM_ROLES_FIRST_2: 'Ведущая Роль 2',
  TEAM_ROLES_FIRST_3: 'Ведущая Роль 3',
  TEAM_ROLES_LAST: 'Наименее  характерная роль',
  TEAM_ROLES_N: 'Роли в команде (норм)',
  INTELLECTUAL_ABILITY: 'Интеллектуальные способности',
  PROF_INTERESTS: 'Карта интересов',
  PROF_INTERESTS_1: 'Интерес 1',
  PROF_INTERESTS_2: 'Интерес 2',
  PROF_INTERESTS_3: 'Интерес 3',
  COMMENT: 'Комментарий',
  EMAIL: 'E-mail',
  JOINED: 'Дата регистрации',
  RELIABILITY: 'Достоверность',
  BEHAVIOUR_1: 'Поведение 1',
  BEHAVIOUR_2: 'Поведение 2',
  BEHAVIOUR_3: 'Поведение 3',
  MANAGER: 'Менеджер',
  TEST_TIME_MMPI: 'Время прохождения личностного опросника',
  TEST_TIME_PROF_INTERESTS: 'Время прохождения карты интересов',
  TEST_TIME_NUMERIC_LOGIC: 'Время прохождения числовой логики',
  TEST_TIME_VERBAL_LOGIC: 'Время прохождения вербальной логики',
  TEST_TIME_NON_VERBAL_LOGIC: 'Время прохождения невербальной логики',
  STRESS_TOLERANCE: 'Уровень стрессоустойчивости',
  ANXIETY: 'Тревожность',
  MANAGEMENT_POTENTIAL: 'Управленческий потенциал',
  MOTIVATORS: 'Мотиваторы',
  MOTIVATOR_1: 'Мотиватор 1',
  MOTIVATOR_2: 'Мотиватор 2',
  MOTIVATOR_3: 'Мотиватор 3',
  DESTRUCTORS: 'Деструкторы',
  DESTRUCTOR_1: 'Деструктор 1',
  DESTRUCTOR_2: 'Деструктор 2',
  DESTRUCTOR_3: 'Деструктор 3',
  COMPETENCES: 'Компетенции',
  COMPETENCES_1: 'Высокоразвитые',
  COMPETENCES_2: 'Среднеразвитые',
  COMPETENCES_3: 'Низкоразвитые',
  COMPLIANCE: 'Расчет соответствия',
};

export const SCALES = {
  0: 'Достоверность/Недостоверность',
  1: 'Отрицание/Преувеличение проблем',
  2: 'Откровенность/Неоткровенность',
  3: 'Нейтральное самочувствие/Обеспокоенность здоровьем',
  4: 'Реалистичность/Пессимистичность оценки',
  5: 'Самодостаточность/Необходимость получения внимания',
  6: 'Самоконтроль/Импульсивность',
  7: 'Женские/Мужские черты в характере',
  8: 'Гибкость/Упорство',
  9: 'Уверенность/Нерешительность',
  10: 'Нормативность/Индивидуализм',
  11: 'Вялость/Энергичность',
  12: 'Замкнутость/Общительность',
};

// export const TEAM_ROLES_1 = 'Реализатор';
// export const TEAM_ROLES_2 = 'Координатор';
// export const TEAM_ROLES_3 = 'Мотиватор';
// export const TEAM_ROLES_4 = 'Генератор идей';
// export const TEAM_ROLES_5 = 'Генератор ресурсов';
// export const TEAM_ROLES_6 = 'Аналитик';
// export const TEAM_ROLES_7 = 'Коллективист';
// export const TEAM_ROLES_8 = 'Контролер';

export const INTELLECTUAL_ABILITY = {
  0: 'Вербальная логика',
  1: 'Числовая логика',
  2: 'Невербальная логика',
};
export const PROF_INTERESTS = {
  0: 'Исследование информации и продвижение товаров/услуг',
  1: 'Контроль и оценка финансов',
  2: 'Образование',
  3: 'Работа с документами и обслуживание процессов',
  4: 'Продажа и переговоры',
  5: 'Медицина',
  6: 'Работа с техникой',
  7: 'Создание событий, текстов или дизайна',
  8: 'Управление процессами на линейном уровне',
  9: 'Управление на уровне топ-менеджмента',
  10: 'Проектирование и программирование',
  11: 'Защита',
};

export function getStrReliability(reliability) {
  if (reliability === 0) return 'Не достоверный';
  if (reliability === 1) return 'Сомнительно достоверный';
  if (reliability === 2) return 'Достоверный';
  return '-';
}
