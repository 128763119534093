import CandidatesToTable from '@/common/exportExcel/CandidatesToTable';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      loadCandidatesResults: 'users/candidates/loadCandidatesResults',
    }),
    exportToTable() {
      this.loadCandidatesResults().then((resp) => {
        // eslint-disable-next-line no-new
        new CandidatesToTable(resp);
      });
    },
  },
};
